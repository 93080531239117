<template>
  <div class="cup-container">
    <Menu/>
    <Header/>
    <InfoBar slogan="cup.infobar.slogans.bozen"
             slogan-background="https://ebikecup.com/images/cups/wien/infobar/EBCUPWIEN_Teaserbild_Wien_01.jpg"/>
    <v-container>
      <v-row class="mt-10 mb-10">
        <v-col cols="12" sm="12" md="12" lg="12" xl="10" offset-xl="1" class="pt-0">
          <v-row>
            <v-col cols="12" id="about" class="pt-0">
              <h1>{{$t('home.about.title')}}</h1>
              <h1>{{$t('home.about.subtitle')}}</h1>
              <h2 class="primary-subtitle">{{$t('cup.bozen.about.underSubtitle')}}</h2>
            </v-col>
            <v-col cols="12" class="text-center">
              <p>
                <span style="max-width: 900px;display: inline-block;">{{$t('cup.bozen.about.paragraph1')}}</span>
              </p>
            </v-col>
            <v-col cols="12" class="pb-0">
              <p style="margin: 0;">
                <span style="max-width: 900px;display: inline-block;">{{$t('home.about.paragraph2')}}</span>
              </p>
            </v-col>
            <v-col cols="12" class="pt-0">
              <p>
                <span style="max-width: 900px;display: inline-block;">{{$t('home.about.paragraph3')}}</span>
              </p>
            </v-col>
            <v-col cols="12">
              <p>
                <span style="max-width: 900px;display: inline-block;">{{$t('home.about.paragraph4')}}</span>
              </p>
            </v-col>
          </v-row>
          <About/>
        </v-col>
      </v-row>
    </v-container>
    <v-img class="gray-separator" src="https://ebikecup.com/images/cups/EBCUP_Trenner_Grau_01.svg"/>
    <div class="home-gray">
      <Schedule/>
    </div>
    <div>
      <Information/>
    </div>
    <div class="home-gray">
      <Register cup-logo="https://ebikecup.com/images/cups/bozen/EBCUP_Logo_Bozen_Dunkel_01-01.svg"
                competition="E-BIKE-CUP-BOZEN"/>
    </div>
    <Banner/>
    <v-img class="gray-separator" src="https://ebikecup.com/images/cups/EBCUP_Trenner_Grau_01.svg"/>
    <Faq/>
    <Partner/>
    <Shop/>
    <div class="home-gray">
      <News/>
    </div>
    <CupsBanner/>
  </div>
</template>

<script>
  import Menu from "../../components/cups/Menu";
  import Header from "../../components/cups/bozen/Header";
  import Schedule from "../../components/cups/bozen/Schedule";
  import Register from "../../components/cups/Register";
  import InfoBar from "../../components/cups/InfoBar";
  import CupsBanner from "../../components/cups/Banner";
  import News from '../../components/home/News';
  import About from '../../components/home/About';
  import Information from '../../components/home/Information';
  import Faq from '../../components/cups/Faq';
  import Partner from '../../components/home/Partner';
  import Banner from '../../components/home/Banner';
  import Shop from '../../components/Shop';

  export default {
    name: 'bozen',
    components: {
      Header, Menu, InfoBar, News, Partner, Banner, About, Schedule, Information, Register, Faq, CupsBanner, Shop,
    },
    data() {
      return {
      };
    },
    mounted() {
      this.globalEvents.$on('goToElementCups', (selector) => {
        if (selector !== undefined) {
          this.$vuetify.goTo(selector, {
            duration: 100,
            offset: 70,
            easing: 'easeInOutCubic',
          });
        }
      });
    },
    methods: {
    }
  };
</script>

<style lang="scss">
  @import "../../assets/scss/variables";
  @import "../../assets/scss/layout";

  .cup-container {
    .home-gray {
      background-color: $basic-gray;
    }
    .white-separator {
      background-color: $basic-gray;
      width: 100%;
      margin-bottom: -2px;
      .v-responsive__sizer {
        padding-bottom: 3% !important;
      }
    }
    .gray-separator {
      background-color: white;
      width: 100%;
      margin-bottom: -2px;
      .v-responsive__sizer {
        padding-bottom: 3% !important;
      }
    }
    h1 {
      font-weight: 400;
      width: 100%;
    }
    .primary-subtitle {
      font-weight: 500;
      font-style: italic;
      color: $primary;
      text-transform: uppercase;
    }
    .text-image-container {
      h1 {
        text-align: left;
        font-style: italic;
        font-weight: 700;
        color: $primary;
        text-transform: uppercase;
        margin-bottom: 15px;
      }
      h4 {
        text-align: left;
        font-style: italic;
        text-transform: uppercase;
        margin-bottom: 15px;
      }
      p, li {
        text-align: left;
      }
      ul {
        list-style: none;
        li {
          &:before {
            content: "•";
            color: $primary;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
          }
        }
      }
      img {
        width: 100%;
        max-width: 500px;
        &.last {
          max-width: unset;
        }
      }
    }
    .hint {
      font-size: 12px;
      text-align: left;
      display: block;
      margin-top: 10px;
    }
  }
</style>
